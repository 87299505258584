import React, { useState } from "react";
import Instruction from "./Instruction";
import ReportingForm from "./ReportingForm";

export default function StolenReport() {
  const [reporting, setReporting] = useState(true);

  const openReportingForm = () => {
    setReporting(true);
  };
  return (
    <div>
      {reporting ? <ReportingForm /> : <Instruction />}

      {reporting ? (
        ""
      ) : (
        <button
          className="mt-8 mb-10 mx-3 bg-gray-900 md:mx-20 rounded-full px-5 text-gray-100 py-2 hover:bg-red-500"
          onClick={openReportingForm}
        >
          Make Report
        </button>
      )}
    </div>
  );
}
