import React from "react";

export default function How() {
  return (
    <div className="w-full h-full mx-4 mt-4 text-3xl">
      <div className="text-center font-bold mt-8 text-4xl">How to Report</div>
      <ul className="mx-3 xl:mx-1 list-disc mt-3 lg:text-lg text-base rounded-lg text-center leading-loose bg-gray-100 list-inside ">
        <li>
          Your vehicle had been stolen between January 2018 and 31st Dec 2022.
        </li>
        <li>
          The theft was reported to the Nigeria Police Force through one of its
          Divisions/Stations
        </li>
        <li>
          If not reported, go to the Police Station to make a report. Otherwise
          Continue
        </li>
        <li>As at date, the vehicle is yet to be recovered, then</li>
      </ul>

      <div className="xl:flex xl:mx-32 text-gray-100 mt-2">
        <div className="flex-1 ml-12 pl-4 border-red-900 text-gray-900 text-xl border-l-2">
          <div className="text-center font-bold">Step 1</div>
          Click Get Started to create a profile and register on the platform
        </div>
        <div className="flex-1 ml-12 pl-4 border-red-900 text-gray-900 text-xl border-l-2 ">
          <div className="text-center font-bold">Step 2</div>
          Select Current State and LGA of residence and click Next
        </div>
        <div className="flex-1 ml-12 pl-4 border-red-900 text-gray-900 text-xl border-l-2 ">
          <div className="text-center font-bold">Step 3</div>
          Provide the required information about the stolen vehicle and submit
        </div>
      </div>
    </div>
  );
}
