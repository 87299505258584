import React from "react";
import Hero from "../components/Hero";
import How from "../components/How";
import Footer from "../components/Footer";

export default function Landing() {
  return (
    <div>
      <Hero />
      <How />
      <Footer />
    </div>
  );
}
