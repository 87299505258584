import "./App.css";
import NavBar from "./components/NavBar";
import Landing from "./pages/Landing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReportIncident from "./pages/ReportIncident";
import ReportStolen from "./pages/ReportStolen";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ReportStolenVehicle from "./components/ReportStolenVehicle";

function App() {
  return (
    <div className="bg-white">
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/stolen" element={<ReportStolen />} />
          <Route path="/report" element={<ReportStolenVehicle />} />
          <Route path="/incident" element={<ReportIncident />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
