import React from "react";
import Footer from "../components/Footer";
import StolenReport from "../components/StolenReport";

export default function ReportStolen() {
  return (
    <div>
      <StolenReport />
      {/* <Footer /> */}
    </div>
  );
}
