import React from "react";
import Footer from "../components/Footer";

export default function About() {
  return (
    <div>
      <div className="md:m-10 md:text-xl text-lg leading-10 md:w-2/4 w-5/6 mx-6 md:h-screen">
        <div className="text-gray-800 font-semibold text-2xl md:text-3xl md:mb-3">
          About this Service
        </div>
        <div className="text-justify leading-10">
          <p className="mb-3">
            Incidences of vehicle theft are reported to the Nigeria Police Force
            for investigation that would lead to the recovery of such vehicles,
            apprehension and prosecution of the offenders. Some of the stolen
            vehicles end up not being recovered and the perpetrators end up
            escaping the long arms of the law. This tends to encourage the
            continued indulgence in the unwholesome activity and attracting
            other criminally-minded persons to engage in it.
          </p>
          <p className="mb-3">
            The Nigeria Police Force in its determined effort to protect the
            properties of citizens in Nigeria, created this website to enable
            members of the public report their vehicles that were stolen from
            1st January 2018 and yet to be recovered. It is a free service and a
            precursor to the transition of the current method of registering
            vehicles by the Nigeria Police Central Motor Registry (CMR) to a
            digitized mode with the aim of checkmating and preventing the
            registration of such stolen vehicles.
          </p>
          <p className="mb-3">
            This service is available up till 31st of December 2022 as the
            modernized CMR including its e-enforcement operations will commence
            on 1st December 2022. Members of the public are therefore enjoined
            to seize this window of opportunity to report the theft of their
            vehicle which are yet to be recovered and support the Nigeria Police
            Force efforts at enhancing national security.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
