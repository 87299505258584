import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    // <div className="transition duration-700 ease-in-out">
    <section className="transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 duration-700 relative bg-center bg-no-repeat bg-cover bg-[url(http://reportcmr.friedenapps.ng/police.jpg)]">
      <div className="absolute inset-0 bg-white/75 sm:bg-transparent sm:bg-gradient-to-r sm:from-white/95 sm:to-white/25"></div>

      <div className="relative px-4 py-32 mx-auto max-w-screen-xl sm:px-6 lg:px-8 lg:h-full lg:items-center lg:flex">
        <div className="max-w-xl text-center sm:text-left">
          <h1 className="text-3xl font-extrabold sm:text-5xl">
            Welcome to the Stolen Vehicle Report Portal
            {/* <strong className="block mt-3  text-3xl font-extrabold text-rose-700">
              What is this service about?
            </strong> */}
          </h1>

          <p className="text-justify font-semibold max-w-lg mt-4 sm:leading-relaxed sm:text-xl">
            One of the functions of the Nigeria Police Force is to protect the
            properties of citizens. This service is created for members of the
            public to report their stolen and yet to be recovered vehicles which
            occured from 1st January, 2018.
          </p>

          <div className="flex flex-wrap mt-8 text-center gap-4">
            {/* <a
              href="#"
              className="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-rose-600 sm:w-auto active:bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring"
            > */}
            <Link
              to="/about"
              className="block w-full px-12 py-3 text-sm font-medium text-white rounded shadow bg-rose-600 sm:w-auto active:bg-rose-500 hover:bg-rose-700 focus:outline-none focus:ring"
            >
              Learn More ...
            </Link>
            {/* </a> */}

            <Link
              to="/stolen"
              className="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-rose-600 sm:w-auto hover:bg-gray-200 hover:text-rose-700 active:text-rose-500 focus:outline-none focus:ring"
            >
              Get Started
            </Link>
            {/* <a
              href="#"
              className="block w-full px-12 py-3 text-sm font-medium bg-white rounded shadow text-rose-600 sm:w-auto hover:text-rose-700 active:text-rose-500 focus:outline-none focus:ring"
            >
              Get Started Now
            </a> */}
          </div>
        </div>
      </div>
    </section>
    // </div>
  );
}
