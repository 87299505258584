import React from "react";
export default function Instruction() {
  return (
    <div className="md:w-2/4 text-xl leading-10 md:mx-10 md:px-12 px-3">
      <p className="mb-5 mt-5 text-2xl font-bold">Instruction</p>
      <p className="text-justify">
        {/* If your vehicle is stolen within the last five years, kindly make a
        report for an attempt recovery. The report is free of charge. */}
        This service, as one of the functions of the Nigeria Police Force to
        protect the property of citizens in Nigeria, is created for members of
        the public to report their stolen vehicle between 1st January 2013 to
        date.
      </p>
      <p className="mt-4 text-justify">
        It is a free service and a precursor to the migration of the current
        manual method of registering vehicles by the CMR with the aim of
        checkmating and preventing the registration of stolen vehicles in the
        digitalized CMR database
      </p>
      <p className="mt-4 text-justify">
        This service is available up till 30th November 2022 as the modernized
        CMR operations including its e-enforcement will commence on 1st December
        2022. Members of the public are therefore enjoined to seize this window
        of opportunity to support the Nigeria Police Force in its efforts at
        enhancing effective service delivery and assisting citizens to recover
        their stolen vehicles.
      </p>
      <p className="font-bold mt-5">Requirements</p>
      <ul className="list-disc ml-8">
        <li>Police Extract of Report </li>
        <li>
          A valid verifiable means of ID (BVN, Driver's License or
          TIN(Coorporate))
        </li>
        <li>Proof of Ownership if available</li>
        <li>Affidavit attested to over theft</li>
      </ul>

      <p className="mt-4">
        If you did not make a report at the time of incident or a report was
        made but you have not collected the police extract. Kindly go to the
        police station you made the report and request for police extract.
      </p>

      <p className="mt-4">
        Please, make sure that the information you will be entring is correct.
        If any information found to be inaccurate, such vehicle reporting will
        be invalidated and no attept will be made at recovering them
      </p>

      <p className="mt-4">Click the below button to start reporting</p>
    </div>
  );
}
