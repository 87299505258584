export const base = process.env.REACT_APP_SERVER_URL;
export const states = "/api/states";
export const lgs = "/api/lgs/";
export const manufacturers = "/api/manufacturers";
export const models = "/api/models/";
export const report = "/api/stolen/report";
export const upload = "/api/stolen/upload";
// const verify = "/api/id/verification";
export const verify = "/api/id/verification/cmrsv";
export const send_token = "/api/otp/";
//const confirm_token = "/api/otp/confirm/";
export const confirm_token = "/api/otp_confirm/";
export const reporter = "/api/reporter";
export const reporter_login = "/api/reporter/login/";
export const get_formations = "/api/formations/";
export const reporter_by_id = "/api/get_reporter/";
export const app_base = process.env.REACT_APP_CLIENT_URL;