import React from "react";

export default function Contact() {
  return (
    <div className="container mx-auto mt-12">
      <div className="font-sans text-3xl text-gray-700">
        Get In Touch With Us
      </div>
      <div className="xl:flex">
        <div className="xl:flex-1 rounded border border-gray-800 mt-12"></div>
        <div className="xl:flex-1 mx-12">
          <div className="mt-12 text-xl font-sans text-gray-800">Contacts</div>
          <div className="leading-loose">
            <div>Abuja Address:</div> <p>The Nigeria Police Force,</p>{" "}
            <p>Central Motor Registry (CMR) Command Centre,</p>
            <p>Force Headquarters,</p>
            <p>Louis Edet House, Shehu Shagari Way,</p>
            <p>Abuja.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
