import React, { useState, useEffect } from "react";
import validator from "validator";
import { useForm } from "react-hook-form";
import { Combobox } from "@headlessui/react";
import colors from "../constants/colors";
import vehicle_types from "../constants/vehicle_types";
import DateTimePicker from "react-datetime-picker";
import Swal from "sweetalert2";
import Loader from "./Loader";
import axios from "axios";
import { formatDate, formatDateTime } from "../utils/date_format";
import { stringToUpper, removeSpaces } from "../utils/data_transform";
import { mask_number } from "../utils/mask_number";
import { sha256 } from "../constants/hashing"

// import { isDisabled } from "@testing-library/user-event/dist/utils";
// import Footer from "./Footer";
import {
  base,
  reporter_by_id,
  states as stateUrl,
  lgs as lgsUrl,
  confirm_token,
  manufacturers as manufacturesUrl,
  models as modelsUrl,
  report as reportUrl,
  upload as uploadUrl,
  verify,
  send_token,
  reporter,
  reporter_login,
  get_formations,
} from "../constants/urls";

export default function ReportingForm() {
  const [step, setStep] = useState(1);
  const [states, setStates] = useState([]);
  const [lgs, setLocalGovernments] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [_reporter_id, setReporterId] = useState(0);
  const [id_type, setIdType] = useState("");
  const [token_channel, setChannel] = useState("");
  const [token_destination, setDestination] = useState("");
  const [police_formations, setPoliceFormations] = useState([]);
  const [ownerDetails, setOwnerDetails] = useState({});
  const [reporterIdNumber, setreporterIdNumber] = useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [inputs, setInputs] = useState({
    //vehicle owner details
    state: "",
    lga: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    gender: "",
    email: "",
    phone: "",
    registered_address: "",
    present_address: "",

    //vehicle reporter details
    r_profile_type: "",
    r_id_number: "",
    r_id_type: "",
    token: "",
    r_first_name: "",
    r_middle_name: "",
    r_last_name: "",
    r_gender: "",
    r_email: "",
    r_phone: "",
    r_address: "",
    r_state: "",
    r_lga: "",
    r_otp_method: "",
    pulled_phone: "",
    pulled_email: "",
    //vehicle owner details
    owner: "",
    owner_id_type: "",
    owner_id_number: "",
    //vehicle details
    owner_id: "",
    reporter_id: "",

    manufacturer: "",
    model: "",

    state_stolen: "",
    lg_stolen: "",
    stolen_time: "",

    current_number: "",
    r_confirm_number: "",
    relationship_to_owner: "",
    valid_email: "",
  });
  const [profileType, setProfileType] = useState("individual");
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [query, setQuery] = useState("");
  const [stolen_time, setTimeOfStealing] = useState(new Date());
  const [date_reported, setDateReported] = useState(new Date());
  const [reportId, setReportId] = useState(0);
  // const [vehicleId, setVehicleId] = useState(0);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isVerifyingNewOwner, setIsVerifyingNewOwner] = useState(false);
  const [owner, setOwner] = useState("");
  const [stolen_state, setStolenState] = useState("");
  const [owner_details, populateOwnerDetails] = useState(false);
  useEffect(() => {
    let url = base + stateUrl;
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setStates(resJson.data);
      });
  }, []);

  useEffect(() => {
    let url = base + manufacturesUrl+"/motorvehicle";
    if (!validator.isURL(url)) {
      return;
    }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        setManufacturers(resJson.data);
      });
  }, []);

  useEffect(() => {
    var max = new Date().getFullYear();
    var min = 1980;
    var years = [];
    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    setYears(years);
  }, []);

  const onConfirmPhoneNumber = () => {
    setChannel("sms");
    setDestination(inputs.r_phone);
    sendToken("sms", inputs.r_phone, inputs.r_email);
    setStep(4);
  };

  const handleChange = (e) => {
   setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
    
    if (
      e.target.name === "r_state" ||
      e.target.name === "state_stolen" ||
      e.target.name === "state"
    ) {
      getLgs(e.target.value);
    } else if (e.target.name === "manufacturer") {
      getModels(e.target.value);
    } else if (e.target.name === "owner") {
      setOwner(e.target.value);
      if (e.target.value == "no") {
        setOwnerDetails({
          first_name: "",
          middle_name: "",
          last_name: "",
          gender: "",
          email: "",
          phone: "",
          state: "",
          lga: "",
          registered_address: "",
          id_type: "",
          id_number: "",
          o_profile_type: profileType,
        });
      } else {
        setInputs({
          ...inputs,
          first_name: inputs.r_first_name,
          middle_name: inputs.r_middle_name,
          last_name: inputs.r_last_name,
          gender: inputs.r_gender,
          email: inputs.r_email,
          phone: inputs.r_phone,
          state: inputs.r_state,
          lga: inputs.r_lga,
          registered_address: inputs.r_address,
          id_type: inputs.r_id_type,
          id_number: inputs.r_id_number,
          o_profile_type: profileType,
          owner_id: _reporter_id, 
        })
        setOwnerDetails({
          first_name: inputs.r_first_name,
          middle_name: inputs.r_middle_name,
          last_name: inputs.r_last_name,
          gender: inputs.r_gender,
          email: inputs.r_email,
          phone:inputs.r_phone,
          state:inputs.r_state,
          lga: inputs.r_lga,
          registered_address: inputs.r_address,
          id_type: inputs.r_id_type,
          id_number: inputs.r_id_number,
          o_profile_type: profileType,
        });
        nextStep();
        
        // let url = base + reporter_by_id + _reporter_id;
        // if (!validator.isURL(url)) {
        //   return;
        // }
        // axios({
        //   method: "get",
        //   url: url,
        // }).then((response) => {
          // console.log(_reporter_id);
          // if (response.data.statusCode === 0) {
            // let resp = response.data.data;
            // setOwnerDetails({
            //   first_name: inputs.r_first_name,
            //   middle_name: inputs.r_middle_name,
            //   last_name: inputs.r_last_name,
            //   gender: inputs.gender,
            //   email: inputs.email,
            //   phone: inputs.phone_number,
            //   state: inputs.state,
            //   lga: inputs.lg,
            //   registered_address: inputs.address,
            //   id_type: inputs.id_type,
            //   id_number: inputs.id_number,
            //   o_profile_type: profileType,
            // });
          // }
        // });
      }
      // if (e.target.value === "yes") {
      //   populateOwnerDetails(true);
      // } else {
      //   populateOwnerDetails(false);
      // }
    }
  };
  // useEffect(() => {
  //   if (owner === "yes") {
  //     if (owner_details === true) {
  //       let url = base + reporter_by_id + _reporter_id;
  //       if (!validator.isURL(url)) {
  //         return;
  //       }
  //       axios({
  //         method: "get",
  //         url: url,
  //       }).then((response) => {
  //         // console.log(_reporter_id);
  //         if (response.data.statusCode === 0) {
  //           let resp = response.data.data;
  //           console.log(resp);
  //           setOwnerDetails({
  //             first_name: resp.first_name,
  //             middle_name: resp.middle_name,
  //             last_name: resp.last_name,
  //             gender: resp.gender,
  //             email: resp.email,
  //             phone: resp.phone_number,
  //             state: resp.state,
  //             lga: resp.lg,
  //             registered_address: resp.address,
  //             id_type: resp.id_type,
  //             id_number: resp.id_number,
  //             o_profile_type: profileType,
  //           });
  //         }
  //       });
  //     } else {
  //       setOwnerDetails({
  //         first_name: "",
  //         middle_name: "",
  //         last_name: "",
  //         gender: "",
  //         email: "",
  //         phone: "",
  //         state: "",
  //         lga: "",
  //         registered_address: "",
  //         id_type: "",
  //         id_number: "",
  //         o_profile_type: profileType,
  //       });
  //     }
  //   }
  // }, [owner_details]);

  const handleStolenStateChange = (e) => {
    //get local government first
    getLgs(e.target.value);

    const url = base + get_formations + e.target.value;
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setPoliceFormations(resJson.data);
        }
      });
  };
  const getFormations = async (state) => {
    const url = base + lgsUrl + get_formations + stolen_state;
    //console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setPoliceFormations(resJson.data);
        }
      });
  };

  // const getFormations = () => {
  //   // if (inputs.state_stolen === "" || inputs.state_stolen === undefined) {
  //   //   return;
  //   // }
  //   let url = base + get_formations + inputs.state_stolen;
  //   // if (!validator.isURL(url)) {
  //   //   return;
  //   // }

  //   //console.log(url);
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((resJson) => {
  //       if (resJson.statusCode === 0) {
  //         setPoliceFormations(resJson.data);
  //       }
  //     });

  //   // axios({
  //   //   method: "get",
  //   //   url: url,
  //   // }).then((response) => {
  //   //   if (response.data.statusCode === 0) {
  //   //     console.log(response);
  //   //     setPoliceFormations(response.data.data);
  //   //   }
  //   // });
  // };
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}`;
  };

  const validateImage = (image, allowed_format_arr, max_allowed_size_in_kb) => {
    let file_type = image.files[0].type;
    let file_size_in_kb = formatBytes(image.files[0].size, 0);
    let type_validated = allowed_format_arr.includes(file_type, 0);
    let size_validated = false;

    if (file_size_in_kb <= max_allowed_size_in_kb) {
      size_validated = true;
    }

    return type_validated && size_validated;
  };

  const onUpload = async (inputs) => {
    setIsLoading(true);
    if (reportId === 0) {
      setIsLoading(false);
      return;
    }
    const extract = document.getElementById("police_extract");
    const proof = document.getElementById("proof_of_ownership");
    const affidavit = document.getElementById("affidavit");

    const url = base + uploadUrl;
    if (!validator.isURL(url)) {
      return;
    }

    const formData = new FormData();

    formData.append("id", reportId);

    if (extract.files.length !== 0) {
      if (validateImage(extract, ["image/jpeg", "image/png"], 100) === false) {
        Swal.fire({
          title: "Error!",
          text: "Police extract file error. Check the size and format",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsLoading(false);
        return;
      }
      formData.append(
        "police_extract",
        extract?.files[0],
        inputs.police_extract
      );
    }

    if (proof.files.length !== 0) {
      if (validateImage(proof, ["image/jpeg", "image/png"], 100) === false) {
        Swal.fire({
          title: "Error!",
          text: "Proof of ownership file error. Check the size and format",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsLoading(false);
        return;
      }
      formData.append(
        "police_extract",
        extract.files[0],
        inputs.police_extract
      );
    }

    if (affidavit.files.length !== 0) {
      if (
        validateImage(affidavit, ["image/jpeg", "image/png"], 100) === false
      ) {
        Swal.fire({
          title: "Error!",
          text: "Affidavit Proof of ownership file error. Check the size and format",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
        setIsLoading(false);
        return;
      }
      formData.append(
        "police_extract",
        extract.files[0],
        inputs.police_extract
      );
    }

    axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setIsLoading(false);
        if (response.data.statusCode === 0) {
          Swal.fire({
            title: "Success!",
            text: "Stolen Vehicle Successfully Reported.",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(function () {
            Swal.fire({
              title: "Success!",
              text: "Report Tracking Number sent to you.",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#1C89DE",
            }).then(() => {
              window.location = "/";
            });
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Error uploading files",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      });
  };

  const onLogin = (useForm_data) => {
    let url = base + reporter_login + useForm_data.login_email;
    if (!validator.isURL(url)) {
      return;
    }
    axios({
      method: "get",
      url: url,
    }).then((response) => {
      if (response.data.statusCode === 0) {
        setDestination(useForm_data.login_email);
        setChannel("email");
        setReporterId(response.data.data.id);
        let responseData = response.data.data;
        sendToken("email", useForm_data.login_email, useForm_data.phone);
        //set inputs parameters
        setreporterIdNumber(responseData.id_number);
        setInputs({
          r_first_name: responseData.first_name,
          r_middle_name: responseData.middle_name,
          r_last_name: responseData.last_name,
          r_phone: responseData.phone_number,
          r_email: responseData.email,
          r_address: responseData.address,
          r_gender: responseData.gender,
          r_state: responseData.state,
          r_id_type: responseData.id_type,
          r_id_number: responseData.id_number,
          r_lga: responseData.lg,
        });
        if (
          (responseData.last_name === "" && responseData.middle_name === "") ||
          (responseData.last_name === undefined &&
            responseData.middle_name === undefined)
        ) {
          setProfileType("corporate");
        } else {
          setProfileType("individual");
        }
        setIsVerified(true);

        setStep(4);
      } else {
        Swal.fire({
          title: "Error!",
          text:
            useForm_data.login_email +
            " not found. You can create new profile below",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
      }
    });
    // console.log(useForm_data.login_email);
  };

  const verifyCorporateReporter = (e) => {
    e.preventDefault();
    setIsVerifyingNewOwner(true);
    let url = base + verify;
    if (!validator.isURL(url)) {
      return;
    }
    let data = {};
    data = {
      idType: inputs.r_id_type,
      id: inputs.r_id_number,
    };
    axios({
      method: "post",
      url: url,
      data,
    }).then((response) => {
      // console.log(response);
      // console.log(response.data.data);
      let resp = response.data.data;
      if (response.data.statusCode === 6) {
        setInputs({
          r_profile_type: "individual",
          r_first_name: resp.first_name,
          r_middle_name: resp.middle_name,
          r_last_name: resp.last_name,
          r_gender: resp.gender,
          r_email: resp.email,
          r_phone: resp.phone_number,
          r_address: resp.address,
          r_state: resp.state,
          r_lga: resp.lg,
        });
        setIsVerifyingNewOwner(false);
        return;
      }

      if (inputs.r_id_type === "nin") {
        setInputs({
          r_profile_type: "individual",
          r_first_name: resp.firstname,
          r_middle_name: resp.middlename,
          r_last_name: resp.surname,
          r_gender:
            resp.gender === "f" || resp.gender === "F" ? "Female" : "Male",
          r_email: resp.email,
          r_phone: resp.telephoneno,
          r_address: resp.residence_AdressLine1 + " " + resp.residence_Town,
          r_state: resp.residence_state,
          r_lga: resp.residence_lga,
        });
        setIsVerifyingNewOwner(false);
        return;
      }
      if (inputs.r_id_type === "dl") {
        setInputs({
          r_profile_type: "individual",
          r_first_name: resp.first_name,
          r_middle_name: resp.middle_name,
          r_last_name: resp.last_name,
          r_gender: resp.gender === "M" ? "Male" : "Female",
          r_email: resp.email,
          r_phone: resp.phone_number,
          r_address: resp.address,
          r_state: "",
          r_lga: "",
        });
        setIsVerifyingNewOwner(false);
        return;
      }
      setIsVerifyingNewOwner(false);
    });
  };

  const verifyNewOwner = (e) => {
    e.preventDefault();
    setIsVerifyingNewOwner(true);
    let url = base + verify;
    if (!validator.isURL(url)) {
      return;
    }
    let data = {};
    // alert(reporterIdNumber + "-566684390351");
    // alert(inputs.owner_id_number + "-566684390351");
    // return;
    if (reporterIdNumber === inputs.owner_id_number) {
      Swal.fire({
        title: "Error!",
        text: "Identification Number is the same as Reporter Identification Number",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      setIsVerifyingNewOwner(false);
      return;
    }
    data = {
      idType: inputs.owner_id_type,
      id: inputs.owner_id_number,
    };
    axios({
      method: "post",
      url: url,
      data,
    }).then((response) => {
      let resp = response.data.data;
      if (response.data.statusCode === 6) {
        setOwnerDetails({
          first_name: resp.first_name,
          middle_name: resp.middle_name,
          last_name: resp.last_name,
          gender: resp.gender,
          email: resp.email,
          phone: resp.phone_number,
          state: resp.state,
          lga: resp.lg,
          registered_address: resp.address,
          id_type: resp.id_type,
          id_number: resp.id_number,
          o_profile_type: profileType,
        });
      } else {
        if (data.idType === "nin") {
          setOwnerDetails({
            first_name: resp.firstname,
            middle_name: resp.middlename,
            last_name: resp.surname,
            gender: resp.gender === "f" ? "Female" : "Male",
            email: resp.email,
            phone: resp.telephoneno,
            state: resp.residence_state,
            lga: resp.residence_lga,
            registered_address:
              resp.residence_AdressLine1 + "" + resp.residence_Town,
            id_type: inputs.owner_id_type,
            id_number: inputs.owner_id_number,
            o_profile_type: profileType,
          });
        } else {
          setOwnerDetails({
            first_name: resp.first_name,
            middle_name: resp.middle_name,
            last_name: resp.last_name,
            gender: resp.gender === "F" ? "Female" : "Male",
            email: "",
            phone: "",
            state: "",
            lga: "",
            registered_address: resp.address,
            id_type: inputs.owner_id_type,
            id_number: inputs.owner_id_number,
            o_profile_type: "individual",
          });
        }
      }
      setIsVerifyingNewOwner(false);
    });
  };

  const onVerify = (e) => {
    e.preventDefault();
    let base_token = process.env.REACT_APP_AUTH_TOKEN
    setIsVerifying(true);
    let data = {
        idType: inputs.r_id_type,
        id: inputs.r_id_number,
    }

    setreporterIdNumber(inputs.r_id_number);
    setIdType(inputs.r_id_type);
    
    if (inputs.r_id_type === "" || inputs.r_id_type === undefined) {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Identification type field is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }

    if (inputs.r_id_number === undefined || inputs.r_id_number === "") {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Identification number is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }

    if (inputs.r_profile_type === undefined || inputs.r_profile_type === "") {
      setIsVerifying(false);
      Swal.fire({
        title: "Error!",
        text: "Profile type field is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }
    if (inputs.r_id_type === "nin") {
      setStep(5);
    } else if (inputs.r_profile_type === "corporate") {
      setStep(10);
    } else if (inputs.r_id_type === "dl") {
      setStep(4);
    } else {
      setStep(1);
    }
    // let url = base + verify;
    // if (!validator.isURL(url)) {
    //   return;
    // }
    // let string = inputs.r_id_number+inputs.r_id_type+"cmrsv"+"meshmellow";
    // sha256(string).then((hash)=>{
    //   try {
    //     fetch(url, {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${base_token}`,
    //         "X-Api-Key": hash,
    //       },
    //       body: JSON.stringify(data),
    //     })
    //     .then((response) => response.json())
    //     .then((response) => {
    //       if (response.success == undefined) {
    //         setIsVerifying(false);
    //         Swal.fire({
    //           title: "Error!",
    //           text: "Verification Failed",
    //           icon: "error",
    //           confirmButtonText: "Ok",
    //           confirmButtonColor: "#1C89DE",
    //         });
    //         return;
    //       } else {
    //         setIsVerified(true);
    //         if (inputs.r_profile_type === "corporate") {
    //           setProfileType("corporate");
    //           let responseData = response.data.data;
    //           if (inputs.r_id_type === "tin") {
    //             // setChannel("sms");
    //             // setDestination(responseData.phone_number);
    //             // sendToken("sms", responseData.phone_number);
    //             setOwnerDetails({
    //               first_name: responseData.name,
    //               middle_name: "NA",
    //               last_name: "NA",
    //               gender: "NA",
    //               email: responseData.email,
    //               phone: "",
    //               state: "NA",
    //               lga: "NA",
    //               registered_address: responseData.street_name,
    //               id_type: "tin",
    //               id_number: inputs.r_id_number,
    //               o_profile_type: "corporate",
    //             });
    //           } else {
    //             // console.log(responseData);
    //             // setChannel("email");
    //             // setDestination(responseData.company_email);
    //             // sendToken("email", responseData.company_email);
    //             setOwnerDetails({
    //               first_name: responseData.company_name,
    //               gender: "NA",
    //               email: responseData.company_email,
    //               phone: "",
    //               state: "NA",
    //               lga: "NA",
    //               registered_address: responseData.company_address,
    //               id_type: "tin",
    //               id_number: inputs.r_id_number,
    //               o_profile_type: "corporate",
    //             });
    //           }
    //         } else {
    //           let responseData = response.message
    //           if (inputs.r_id_type === "nin") {
    //             let channel = "sms";
    //             let destination = responseData.telephoneno;
    //             setChannel(channel);
    //             setDestination(destination);
    //             // sendToken(channel, destination);
    //             setReporterId(responseData.id);
  
    //             setInputs({
    //               valid_email: responseData.email === undefined && "no",
    //               r_first_name: responseData.firstName,
    //               r_middle_name: responseData.middleName,
    //               r_last_name: responseData.surname,
    //               r_phone: responseData.telephoneno,
    //               pulled_phone: responseData.telephoneno,
    //               pulled_email: responseData.email,
    //               r_address:
    //                 responseData.residence_Address +
    //                 "," +
    //                 responseData.residence_Town +
    //                 "," +
    //                 responseData.residence_state,
    //               // r_gender: responseData.gender === "m" ? "Male" : "Female",
    //               // r_email: responseData.email,
    //             });
    //           } else if (inputs.r_id_type === "dl") {
    //             setChannel("sms");
    //             setDestination(inputs.r_phone);
    //             sendToken("sms", inputs.r_phone, inputs.r_email);
    //             setInputs({
    //               r_first_name: responseData.first_name,
    //               r_middle_name: responseData.middle_name,
    //               r_last_name: responseData.last_name,
    //               r_phone: inputs.r_phone,
    //               r_gender: responseData.gender,
    //             });
    //           } else {
    //             setChannel("sms");
    //             setDestination(responseData.phone_number);
    //             // sendToken("sms", responseData.phone_number);
    //             setInputs({
    //               r_first_name: responseData.first_name,
    //               r_middle_name: responseData.middle_name,
    //               r_last_name: responseData.last_name,
    //               r_phone: inputs.r_phone,
    //               r_address: responseData.address,
    //               r_gender: responseData.gender,
    //             });
    //           }
    //         }
    //         Swal.fire({
    //           title: "Success!",
    //           text: "Verification Successful",
    //           icon: "success",
    //           confirmButtonText: "Ok",
    //           confirmButtonColor: "#1C89DE",
    //         }).then(() => {
    //           if (inputs.r_id_type === "nin") {
    //             setStep(2);
    //           } else if (inputs.r_profile_type === "corporate") {
    //             setStep(10);
    //           } else if (inputs.r_id_type === "dl") {
    //             setStep(4);
    //           } else {
    //             setStep(1);
    //           }
    //         });
    //         setIsVerifying(false);
    //       }
    //     });
    //   } catch (error) {
    //     setIsVerifying(false);
    //     Swal.fire({
    //       title: "Error!",
    //       text: "Error Verifying",
    //       icon: "error",
    //       confirmButtonText: "Ok",
    //       confirmButtonColor: "#1C89DE",
    //     });
    //   }  
    // })
  };

  const sendToken = (channel, destination, auxiliary) => {
    let tokenUrl =
      base + send_token + channel + "/" + destination + "/" + auxiliary;
    // base + send_token + channel + "/" + destination + "/" + auxiliary;
    if (!validator.isURL(tokenUrl)) {
      return;
    }
    axios({
      method: "get",
      url: tokenUrl,
    }).then((response) => {
      if (response.status === 200) {
        // console.log("sent");
        return true;
      } else {
        // console.log("Not sent");
        return false;
      }
    });
  };

  const onSubmitReporter = (useForm_data) => {
    //let submit reporter's data to the server
    setIsLoading(true);
    let data = {
      first_name: inputs.r_first_name?.toUpperCase(),
      middle_name: inputs.r_middle_name?.toUpperCase(),
      last_name: inputs.r_last_name?.toUpperCase(),
      gender: inputs.r_gender,
      email: step === 10 ? useForm_data.email : inputs.r_email,
      phone_number: useForm_data.r_phone,
      id_type: id_type,
      pulled_email: inputs.pulled_email,
      pulled_phone: inputs.pulled_phone,
      id_number: useForm_data.r_id_number,
      state: useForm_data.r_state,
      lg: useForm_data.r_lga,
      address: inputs.r_address,
      status: "Pending"
    };
    let url = base + reporter;
    if (!validator.isURL(url)) {
      return;
    }
    axios({
      method: "post",
      url: url,
      data,
    }).then((response) => {
      console.log(response);
      if (response.data.statusCode === 0) {
        // setReporterId(response.data.data);
        setReporterId(response.data.data);
        if (step === 10) {
          setStep(7);
        } else {
          nextStep();
        }
        setIsLoading(false);
      }
    });
  };
  const onSubmit = (useForm_data) => {
    setIsLoading(true);
    inputs.stolen_time = formatDateTime(stolen_time);
    inputs.color = selectedColor;

    let data = {
      reporter_id: parseInt(_reporter_id),
      owner: {
        first_name: ownerDetails.first_name,
        middle_name: ownerDetails.middle_name,
        last_name: ownerDetails.last_name,
        type:
          inputs.o_profile_type === undefined
            ? profileType
            : inputs.o_profile_type,
        gender: owner === "yes" ? ownerDetails.gender : useForm_data.gender, //owner === "yes" ? ownerDetails.gender : useForm_data.gender,
        email: owner === "yes" ? ownerDetails.email : useForm_data.email, //owner === "yes" ? ownerDetails.email : useForm_data.email,
        phone:
          ownerDetails.id_type === "nin"
            ? ownerDetails.phone
            : useForm_data.phone, //owner === "yes" ? ownerDetails.phone : useForm_data.phone,
        state:
          ownerDetails.id_type === "nin"
            ? ownerDetails.state
            : useForm_data.state, //owner === "yes" ? ownerDetails.state : useForm_data.state,
        lg: owner === "yes" ? ownerDetails.lga : useForm_data.lga, //owner === "yes" ? ownerDetails.lga : useForm_data.lga,
        registered_address: ownerDetails.registered_address,
        // owner === "yes"
        //   ? ownerDetails.registered_address
        //   : useForm_data.registered_address,
        present_address: useForm_data.present_address,
        id_type: ownerDetails.id_type,
        id_number: ownerDetails.id_number,
        status: "Not Verified",
      },

      vehicle: {
        license_plate: stringToUpper(removeSpaces(useForm_data.license_plate)),
        chassis_number: stringToUpper(
          removeSpaces(useForm_data.chassis_number)
        ),
        engine_number: stringToUpper(useForm_data.engine_number),
        manufacturer: useForm_data.manufacturer,
        model: useForm_data.model,
        year: useForm_data.year,
        vehicle_type: useForm_data.vehicle_type,
        color: useForm_data.color,
        vehicle_usage: useForm_data.vehicle_usage,
        vehicle_status: "Reported",
        mla: useForm_data.mla,
        mla_address: useForm_data.mla_address,
        vehicle_source: useForm_data.source_of_vehicle,
      },
      report: {
        reporter_id: parseInt(_reporter_id),
        entry_date: formatDate(new Date()),
        where_state: useForm_data.state_stolen,
        where_lg: useForm_data.lg_stolen,
        where_location: useForm_data.stolen_location,
        time_of_stealing: formatDateTime(stolen_time),
        mode_of_stealing: useForm_data.mode_of_stealing,
        where_reported: useForm_data.police_formation,
        date_reported: formatDate(date_reported),
        description: useForm_data.detailed_description,
        extract_number: useForm_data.extract_number,
        reported_by: owner === "yes" ? "Owner" : useForm_data.relationship,
        status: "Pending",
      },
    };
    let url = base + reportUrl;
    if (!validator.isURL(url)) {
      return;
    }
    axios({
      method: "post",
      url: url,
      data,
    }).then((response) => {
      if (response.data.statusCode === 0) {
        setReportId(response.data.data.reportId);
        // setVehicleId(response.data.data.vehicleId);
        nextStep();
        setIsLoading(false);
      }
    });
    //lets make submission. but before that, lets deal with date and time issue on report page
  };

  const nextStep = () => {
    if (
      (inputs.r_address === "" || inputs.r_address === undefined) &&
      step === 5
    ) {
      Swal.fire({
        title: "Error!",
        text: "Address is required",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#1C89DE",
      });
      return;
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const getLgs = async (state) => {
    const url = base + lgsUrl + state;
    //console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        if (resJson.statusCode === 0) {
          setLocalGovernments(resJson.data);
        }
      });
  };

  const onConfirmToken = (e) => {
    e.preventDefault();
    setIsVerifying(true);
    let confirmTokenUrl =
      base + confirm_token + token_destination + "/" + inputs.token;
    if (!validator.isURL(confirmTokenUrl)) {
      return;
    }
    axios({
      method: "get",
      url: confirmTokenUrl,
    }).then((response) => {
      // console.log(response);
      if (response.status === 200) {
        if (response.data.statusCode === 0) {
          setIsVerifying(false);
          Swal.fire({
            title: "Success!",
            text: "Token Confirmed",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          }).then(() => {
            if (token_channel === "sms" || token_channel === "voice") {
              nextStep();
            } else {
              setStep(6);
            }
          });
        } else {
          setIsVerifying(false);
          Swal.fire({
            title: "Error!",
            text: "Token Confirmation failed",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#1C89DE",
          });
        }
      } else {
        setIsVerifying(false);
        Swal.fire({
          title: "Error!",
          text: "Error Confirming Token. Pleas Regenerate.",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#1C89DE",
        });
      }
    });
  };
  const getModels = async (manufacturer) => {
    const url = base + modelsUrl + manufacturer+"/motorvehicle";
    // if (!validator.isURL(url)) {
    //   return;
    // }
    fetch(url)
      .then((res) => res.json())
      .then((resJson) => {
        // console.log(resJson);
        if (resJson.statusCode === 0) {
          setModels(resJson.data);
        }
      });
  };

  const filteredColors =
    query === ""
      ? colors
      : colors.filter((color) => {
          return color.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <div className="">
      <div className="text-2xl pt-4 pb-4 bg-blue-200 font-bold text-center">
        STOLEN VEHICLE REPORT PORTAL
      </div>
      <div className="lg:flex pt-8 bg-gray-100">
        <div
          className={`xl:flex-initial ${step > 4 ? "xl:w-1/6" : "xl:w-2/6"}`}
        ></div>
        <div
          className={`xl:flex-1 ${step === 1 ? "h-screen" : "h-full"}
          ${step === 2 ? "h-screen" : "h-full"}
          ${step === 5 ? "h-screen" : "h-full"} 
          ${step === 0 ? "" : ""} 
         lg:h-screen xl:px-1 xl:w-4/6 mx-4`}
        >
          <form encType="multipart/form-data">
            {/*..................................>>Step 0 ...........................*/}
            {step === 0 && (
              <div>
                {/* <div className="text-xl font-semibold text-center mb-4">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                <div className="text-2xl font-bold mt-12 text-center">
                  Login Here
                </div>
                <label
                  htmlFor="email"
                  className="mt-12 block text-gray-900 text-lg"
                >
                  Email<span className="text-red-500 text-lg"> *</span>
                </label>
                <input
                  type="email"
                  name="login_email"
                  {...register("login_email", { required: true })}
                  aria-invalid={errors.r_email ? "true" : "false"}
                  onChange={handleChange}
                  className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                />
                {errors.login_email?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Email is required
                  </p>
                )}
                <button
                  onClick={handleSubmit(onLogin)}
                  // className="w-full md:ml-12 uppercase rounded-lg bg-black text-white h-10"
                  className="mt-4 bg-blue-500 text-lg w-full py-2 rounded-lg text-gray-100"
                >
                  Login
                  {isVerifying && <Loader />}
                </button>
                <div className="mt-1 text-center">
                  I don't have a profile.{" "}
                  <span
                    onClick={() => {
                      setStep(1);
                    }}
                    className={`font-bold text-blue-800 cursor-pointer hover:text-gray-700`}
                  >
                    Create Profile
                  </span>
                </div>
              </div>
            )}
            {/* ...............................Step 1 here.............................*/}
            {/* ..........................Reporter Verification.........................*/}
            {step === 1 && (
              <div>
                {/* <div className="text-xl font-semibold text-center mb-4">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                <div className="text-center text-2xl font-bold">
                  Create Profile
                </div>
                <label
                  htmlFor="r_profile_type"
                  className="block mt-12 text-gray-900 text-lg mb-1 font-sans"
                >
                  Profile Type<span className="text-red-500 text-lg"> *</span>
                </label>
                <div onChange={handleChange}>
                  <label className="text-lg">
                    <input
                      type="radio"
                      className="mr-2 mb-4"
                      name="r_profile_type"
                      value="individual"
                      required={true}
                    />
                    Personal
                  </label>
                  <label className="text-lg">
                    <input
                      type="radio"
                      className="ml-8 mr-2 mb-4"
                      name="r_profile_type"
                      value="corporate"
                      disabled={true}
                      required={true}
                    />
                    Organization
                  </label>
                </div>
                <label
                  htmlFor="typeOfId"
                  className="block text-gray-900 text-lg mt-3"
                >
                  Identification Type{" "}
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <select
                  name="r_id_type"
                  {...register("r_id_type", { required: true })}
                  onChange={handleChange}
                  className="w-full mb-4 bg-white rounded-lg h-10 text-lg mt-1 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                >
                  <option value="">Select an ID</option>
                  {/* {inputs.r_profile_type === "individual" && (
                    <option value="dl">National Driver's License</option>
                  )} */}
                  {inputs.r_profile_type === "corporate" && (
                    <option value="cac">Corporate Affairs Commission</option>
                  )}
                  {inputs.r_profile_type === "corporate" && (
                    <option value="tin">Tax Identification Number</option>
                  )}
                  {inputs.r_profile_type === "individual" && (
                    <option value="nin">
                      National Identification Number(NIN)
                    </option>
                  )}
                </select>
                <label
                  htmlFor="idNumber"
                  className="block text-gray-900 text-lg"
                >
                  Identification Number
                  <span className="text-red-500 text-lg"> *</span>
                </label>
                <input
                  name="r_id_number"
                  type="text"
                  {...register("r_id_number", { required: true })}
                  onChange={handleChange}
                  // {...register("idNumber", { required: true })}
                  // className="w-5/6 h-12 text-lg bg-gray-100 mb-4 rounded-lg border-2 hover:border-green-800 py-1 px-2"
                  className="w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2 border border-gray-600"
                />
                {inputs.r_id_type === "dl" && (
                  <>
                    <label
                      htmlFor="idNumber"
                      className="block text-gray-900 text-lg"
                    >
                      Phone
                      <span className="text-red-500 text-lg"> *</span>
                    </label>
                    <input
                      name="r_phone"
                      type="text"
                      {...register("r_phone", { required: true })}
                      onChange={handleChange}
                      aria-invalid={errors.r_phone ? "true" : "false"}
                      className="border border-gray-600 w-full mb-4 bg-white rounded-lg h-10 text-xl mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                    />
                    {errors.r_phone?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Email is required
                      </p>
                    )}
                  </>
                )}

                <button
                  onClick={(e) => {
                    onVerify(e);
                  }}
                  className="bg-blue-500 w-full py-2 font-bold mt-8 rounded-lg text-gray-100 text-lg"
                >
                  Next
                  {isVerifying && <Loader />}
                </button>
                <div className="mt-1 text-center text-lg">
                  I have already created a profile.{" "}
                  <span
                    onClick={() => {
                      setStep(0);
                    }}
                    className={`font-bold text-blue-800 cursor-pointer hover:text-gray-700`}
                  >
                    Login here
                  </span>
                </div>
              </div>
            )}
            {step === 2 && (
              <>
                <p className="text-center mt-5 text-gray-800 text-3xl mb-4 font-bold">
                  Confirm Reporter's Email and Phone Number
                </p>
                <div>
                  <div
                    className={`${
                      inputs.current_number === "no" ? "hidden" : "block"
                    }`}
                  >
                    <label
                      htmlFor="token"
                      className="block text-gray-900 mt-3 text-center text-lg"
                    >
                      Is this your current phone number
                      <span className="text-red-600">
                        {mask_number(inputs.r_phone)}
                      </span>
                      <div onChange={handleChange} className="ml-5 text-center">
                        <label>
                          <input
                            type="radio"
                            className="mr-2"
                            name="current_number"
                            value="yes"
                            required={true}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            className="ml-8 mr-2 mb-4"
                            name="current_number"
                            value="no"
                            required={true}
                          />
                          No
                        </label>
                      </div>
                    </label>
                  </div>
                  {inputs.current_number === "no" && (
                    <>
                      <label
                        htmlFor="token"
                        className="block text-gray-900 mt-3 text-lg"
                      >
                        Current Phone Number{" "}
                      </label>
                      <input
                        name="r_phone"
                        type="text"
                        {...register("r_phone", { required: true })}
                        onChange={handleChange}
                        className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                      />
                    </>
                  )}

                  {inputs.valid_email === "no"}
                  <div
                    className={`${
                      inputs.valid_email === "no" ? "hidden" : "block"
                    }`}
                  >
                    <label
                      htmlFor="token"
                      className="block text-gray-900 mt-3 text-center text-lg"
                    >
                      Is this a valid email address{" "}
                      <span className="text-red-600">{inputs.r_email}</span>
                      <div onChange={handleChange} className="ml-5 text-center">
                        <label>
                          <input
                            type="radio"
                            className="mr-2"
                            name="valid_email"
                            value="yes"
                            required={true}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            className="ml-8 mr-2 mb-4"
                            name="valid_email"
                            value="no"
                            required={true}
                          />
                          No
                        </label>
                      </div>
                    </label>
                  </div>

                  {inputs.valid_email === "no" && (
                    <>
                      <label
                        htmlFor="token"
                        className="block text-gray-900 mt-3 text-lg"
                      >
                        Valid Email Address{" "}
                      </label>
                      <input
                        name="r_email"
                        type="text"
                        {...register("r_email", { required: true })}
                        onChange={handleChange}
                        className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                      />
                    </>
                  )}
                  <button
                    className="bg-blue-500 text-lg mt-4 w-full py-2 rounded-lg text-gray-100"
                    onClick={handleSubmit(onConfirmPhoneNumber)}
                  >
                    Confirm
                    {isLoading && <Loader />}
                  </button>
                </div>
              </>
            )}
            {step === 10 && (
              <>
                <div className="">
                  {/* <div className="text-center mb-3 text-xl font-semibold">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                  <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                    Representative Details
                  </p>
                  <div className="xl:flex">
                    <div className="xl:flex-1">
                      <label
                        htmlFor="organization_name"
                        className="block text-gray-900 text-lg"
                      >
                        Organization Name
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        readOnly
                        value={ownerDetails.first_name || ""}
                        onChange={handleChange}
                        placeholder="Organization Name"
                        className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                      />
                    </div>
                    <div className="xl:flex-1 ml-3">
                      {" "}
                      <label
                        htmlFor="address"
                        className="block text-gray-900 text-lg"
                      >
                        Organization Address
                      </label>
                      {ownerDetails.registered_address === "" ||
                      ownerDetails.registered_address === undefined ? (
                        <>
                          <input
                            type="text"
                            name="address"
                            {...register("present_address", { required: true })}
                            aria-invalid={
                              errors.present_address ? "true" : "false"
                            }
                            onChange={handleChange}
                            placeholder="Organization Address"
                            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                          />
                          {errors.present_address?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Organization address is required
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name="address"
                            readOnly={true}
                            value={ownerDetails.registered_address || ""}
                            onChange={handleChange}
                            placeholder="Organization Address"
                            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                          />
                        </>
                      )}
                    </div>
                    <div className="xl:flex-1 ml-3">
                      {" "}
                      <label
                        htmlFor="phone"
                        className="block text-gray-900 text-lg"
                      >
                        Organization Phone
                      </label>
                      {ownerDetails.phone === "" ||
                      ownerDetails.phone === undefined ? (
                        <>
                          <input
                            type="text"
                            name="phone"
                            onChange={handleChange}
                            {...register("phone", { required: true })}
                            aria-invalid={errors.phone ? "true" : "false"}
                            placeholder="080xxxxxx12"
                            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                          />
                          {errors.phone?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Organization phone is required
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type="text"
                            name="phone"
                            readOnly={true}
                            value={ownerDetails.phone || ""}
                            onChange={handleChange}
                            placeholder="081xxxxxx12"
                            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="xl:flex xl:mt-5">
                    <div className="xl:flex-1">
                      <label
                        htmlFor="r_id_type"
                        className="block text-gray-900 text-lg"
                      >
                        Org Rep ID Type
                      </label>
                      <select
                        name="r_id_type"
                        {...register("r_id_type", {
                          required: true,
                        })}
                        onChange={handleChange}
                        className="w-full bg-white rounded-lg h-12 text-lg leading-loose hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select an ID</option>

                        {/* <option value="dl">National Driver's License</option> */}
                        <option value="nin">
                          National Identification Number(NIN)
                        </option>
                      </select>
                    </div>
                    <div className="xl:flex-1 xl:ml-3">
                      <hr className="xl:hidden mt-4" />
                      <label
                        htmlFor="r_id_number"
                        className="block text-gray-900 text-lg"
                      >
                        Rep ID Number
                      </label>
                      <input
                        type="text"
                        name="r_id_number"
                        id="r_id_number"
                        onChange={handleChange}
                        className="w-full h-12 uppercase text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                      />
                    </div>
                    <div className="xl:flex-1 xl:ml-3 xl:mt-1">
                      <button
                        onClick={(e) => {
                          verifyCorporateReporter(e);
                        }}
                        className="bg-blue-500 rounded-lg text-white w-full mt-6 py-3 hover:bg-black"
                      >
                        Verify
                        {isVerifyingNewOwner && <Loader />}
                      </button>
                    </div>
                  </div>
                  <div className="xl:flex xl:mt-5">
                    <div className="xl:flex-1 mr-2">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="rep_first_name"
                        className="block text-gray-900 text-lg"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        name="r_first_name"
                        readOnly
                        value={inputs.r_first_name || ""}
                        onChange={handleChange}
                        placeholder="First Name"
                        className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                      />
                    </div>
                    <div className="xl:flex-1 mr-2">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="rep_middle_name"
                        className="block text-gray-900 text-lg"
                      >
                        Middle Name
                      </label>
                      <input
                        type="text"
                        name="r_middle_name"
                        readOnly
                        value={inputs.r_middle_name || ""}
                        onChange={handleChange}
                        placeholder="Middle Name"
                        className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                      />
                    </div>
                    <div className="xl:flex-1 mr-2">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="last_name"
                        className="block text-gray-900 text-lg"
                      >
                        Last Name
                      </label>

                      <input
                        type="text"
                        placeholder="Last Name"
                        name="r_last_name"
                        readOnly={
                          inputs.r_last_name !== "" ||
                          inputs.r_last_name !== undefined
                            ? false
                            : true
                        }
                        value={inputs.r_last_name || ""}
                        onChange={handleChange}
                        className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      />
                    </div>
                  </div>
                  <div className="xl:flex xl:mt-5">
                    <div className="xl:flex-1 xl:mr-3">
                      <hr className="xl:hidden mt-3" />

                      <label
                        htmlFor="type"
                        className="block text-gray-900 text-lg"
                      >
                        Gender
                      </label>

                      <select
                        name="r_gender"
                        value={inputs.r_gender || ""}
                        onChange={handleChange}
                        className="w-full rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>

                    <div className="xl:flex-1 mr-3">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="email"
                        className="block text-gray-900 text-lg"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        // readOnly={ownerDetails.email === "" ? false : true}
                        {...register("email", {
                          required: owner === "yes" ? false : true,
                        })}
                        aria-invalid={errors.email ? "true" : "false"}
                        // value={ownerDetails.email || ""}
                        onChange={handleChange}
                        className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      />
                      {errors.email?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Email is required
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="r_phone"
                        className="block text-gray-900 text-lg"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        value={inputs.r_phone || ""}
                        name="r_phone"
                        placeholder="080xxxxxxxx"
                        onChange={handleChange}
                        className="w-full rounded-lg border-2 hover:border-green-800 h-12 text-lg py-1 px-2"
                      />
                    </div>
                  </div>
                  <div className="xl:flex mt-3">
                    <div className="xl:flex-1">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="address"
                        className="mt-3 block text-gray-900 text-lg"
                      >
                        Registered/Residential Address
                      </label>
                      <input
                        type="text"
                        name="r_address"
                        placeholder="Residential Address"
                        // {...register("r_address", {
                        //   required: "Address is required",
                        //   minLength: {
                        //     value: 10,
                        //     message: "Address is too short",
                        //   },
                        // })}
                        readOnly={true}
                        value={inputs.r_address || ""}
                        onChange={handleChange}
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      />
                      {/* {errors.r_address ? (
                        <>
                          {errors.r_address?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              {errors.r_address.message}
                            </p>
                          )}
                          {errors.r_address?.type === "minLength" && (
                            <p role="alert" className="text-sm text-red-500">
                              {errors.r_address.message}
                            </p>
                          )}
                        </>
                      ) : null} */}
                    </div>
                  </div>
                  <div className="xl:flex mt-2">
                    <div className="xl:flex-1 xl:mr-3">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="state"
                        className="mt-3 block text-gray-900 text-lg"
                      >
                        Residential State
                      </label>
                      <select
                        name="r_state"
                        {...register("r_state", { required: true })}
                        aria-invalid={errors.r_state ? "true" : "false"}
                        onChange={handleChange}
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Select State</option>
                        {states.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.r_state?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          State is required
                        </p>
                      )}
                    </div>
                    <div className="xl:flex-1">
                      <hr className="xl:hidden mt-3" />
                      <label
                        htmlFor="lga"
                        className="mt-3 block text-gray-900 text-lg"
                      >
                        Residential Local Government
                      </label>
                      <select
                        name="r_lga"
                        onChange={handleChange}
                        {...register("r_lga", { required: true })}
                        aria-invalid={errors.r_lga ? "true" : "false"}
                        className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      >
                        <option value="">Select Local Government</option>
                        {lgs.map((val, index) => {
                          return (
                            <option key={index} value={val}>
                              {val}
                            </option>
                          );
                        })}
                      </select>
                      {errors.r_lga?.type === "required" && (
                        <p role="alert" className="text-sm text-red-500">
                          Local Government is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="flex-1 w-1/2">
                      <button
                        className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                        onClick={() => {
                          setStep(1);
                        }}
                      >
                        {"<<"}Previous
                      </button>
                    </div>
                    <div className="flow-left">
                      <button
                        disabled={!isVerified}
                        className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                        onClick={handleSubmit(onSubmitReporter)}
                      >
                        Next{">>"}
                        {isLoading && <Loader />}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {step === 4 && (
              <div>
                <p className="text-center mt-5 text-gray-800 text-3xl mb-4 font-bold">
                  Confirm Reporter's Identity
                </p>
                {/* <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Confirm your Identity
                </p> */}
                <p className="text-center mt-6 text-red-600 text-lg">
                  A token has been sent to
                  {" " + mask_number(token_destination)}
                </p>
                <label
                  htmlFor="token"
                  className="block text-gray-900 mt-3 text-lg"
                >
                  Token
                </label>
                <input
                  name="token"
                  type="text"
                  // {...register("r_id_number", { required: true })}
                  onChange={handleChange}
                  // {...register("idNumber", { required: true })}
                  // className="w-5/6 h-12 text-lg bg-gray-100 mb-4 rounded-lg border-2 hover:border-green-800 py-1 px-2"
                  className="w-full bg-white border-2 border-gray-300 rounded-lg h-10 mb-1 text-lg mt-1 text-gray-800 leading-loose hover:border-green-800 py-1 px-2"
                />
                <div className="text-center text-lg mt-4">
                  I did not receive a token
                  <span
                    onClick={() => {
                      sendToken("voice", token_destination);
                      Swal.fire({
                        title: "Success!",
                        text: "Token Resent",
                        icon: "success",
                        confirmButtonText: "Ok",
                        confirmButtonColor: "#1C89DE",
                      });
                    }}
                    className="ml-3 bg-blue-500 cursor-pointer text-gray-100 px-24 py-1 rounded-lg"
                  >
                    {"Resend"}
                  </span>
                </div>
                <button
                  onClick={(e) => {
                    onConfirmToken(e);
                  }}
                  // className="w-full md:ml-12 uppercase rounded-lg bg-black text-white h-10"
                  className="bg-blue-500 text-lg mt-4 w-full py-2 rounded-lg text-gray-100"
                >
                  Confirm Token
                  {isVerifying && <Loader />}
                </button>
              </div>
            )}
            {step === 5 ? (
              <div className="">
                {/* <div className="text-center mb-3 text-xl font-semibold">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Reporter Details
                </p>
                <div className="xl:flex">
                  <div className="xl:flex-1 mr-2">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="firstname"
                      className="block text-gray-900 text-lg"
                    >
                      {profileType === "individual"
                        ? "First Name"
                        : "Organization name"}
                    </label>
                    <input
                      type="text"
                      name="r_first_name"
                      // value={inputs.r_first_name || ""}
                      onChangeCapture={handleChange}
                      // onChange={(e)=>{
                      //   handleChange(e);
                      // }}
                      {...register("r_first_name", { required: true })}
                      placeholder="First Name"
                      className="uppercase w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                    />
                    {errors.r_first_name?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        First is required
                      </p>
                    )}
                  </div>

                  <div className="xl:flex-1 mr-2">
                    <hr className="xl:hidden mt-3" />
                    {profileType === "individual" && (
                      <label
                        htmlFor="middleName"
                        className="block text-gray-900 text-lg"
                      >
                        Middle Name
                      </label>
                    )}
                    {profileType === "individual" && (
                      <>
                        <input
                          type="text"
                          placeholder="Middle Name"
                          name="r_middle_name"
                          // value={inputs.r_middle_name || ""}
                          onChangeCapture={handleChange}
                          {...register("r_middle_name", { required: false })}
                          className="uppercase w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                        />
                        {errors.r_middle_name?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            Middle Name is required
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  
                  <div className="xl:flex-1">
                    <hr className="xl:hidden mt-3" />
                    {profileType === "individual" && (
                      <label
                        htmlFor="lastname"
                        className="block text-gray-900 text-lg"
                      >
                        Last Name
                      </label>
                    )}
                    {profileType === "individual" && (<>
                      <input
                        type="text"
                        // value={inputs.r_last_name || ""}
                        name="r_last_name"
                        onChangeCapture={handleChange}
                        {...register("r_last_name", { required: true })}
                        placeholder="Last Name"
                        className="uppercase w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      />
                      {errors.r_last_name?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            Last Name is required
                          </p>
                      )}
                    </>
                      
                    )}
                  </div>
                </div>
                <div className="xl:flex mt-4">
                  <hr className="xl:hidden mt-3" />
                  <div className="xl:flex-1 mr-3">
                    {profileType === "individual" && (
                      <label
                        htmlFor="type"
                        className="mt-3 block text-gray-900 text-lg"
                      >
                        Gender
                      </label>
                    )}
                    {/* { */}
                    {profileType === "individual" && (<>
                      <select
                        name="r_gender"
                        // value={inputs.r_gender || ""}
                        onChangeCapture={handleChange}
                        // disabled={true}
                        {...register("r_gender", { required: true })}
                        className="w-full rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      {errors.r_gender?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            Gender is required
                          </p>
                      )}
                    </>
                      
                    )}
                    {/* } */}
                  </div>
                  <div className="xl:flex-1 mr-3">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="pulled_phone"
                      className="mt-3 block text-gray-900 text-lg"
                    >
                      Phone
                    </label>
                    <input
                      type="text"
                      // value={inputs.pulled_phone || ""}
                      name="r_phone"
                      placeholder="080xxxxxxxx"
                      onChangeCapture={handleChange}
                      {...register("r_phone", { required: true })}
                      className="w-full rounded-lg border-2 hover:border-green-800 h-12 text-lg py-1 px-2"
                    />
                     {errors.r_phone?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            Phone is required
                          </p>
                    )}
                  </div>
                  <div className="xl:flex-1">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="email"
                      className="mt-3 block text-gray-900 text-lg"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="r_email"
                      {...register("r_email", { required: true })}
                      // aria-invalid={errors.r_email ? "true" : "false"}
                      // value={inputs.r_email}
                      onChangeCapture={handleChange}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    />
                    {errors.r_email?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Email is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="xl:flex mt-3">
                  <div className="xl:flex-1">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="address"
                      className="mt-3 block text-gray-900 text-lg"
                    >
                      Residential Address
                    </label>
                    <input
                      type="text"
                      name="r_address"
                      placeholder="Residential Address"
                      {...register("r_address", {
                        required: "Address is required",
                        minLength: {
                          value: 10,
                          message: "Address is too short",
                        },
                      })}
                      value={inputs.r_address || ""}
                      onChangeCapture={handleChange}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    />
                    {errors.r_address ? (
                      <>
                        {errors.r_address?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.r_address.message}
                          </p>
                        )}
                        {errors.r_address?.type === "minLength" && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.r_address.message}
                          </p>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="xl:flex mt-2">
                  <div className="xl:flex-1 xl:mr-3">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="state"
                      className="mt-3 block text-gray-900 text-lg"
                    >
                      Current State of Residence
                    </label>
                    <select
                      name="r_state"
                      {...register("r_state", { required: true })}
                      aria-invalid={errors.r_state ? "true" : "false"}
                      onChangeCapture={handleChange}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select State</option>
                      {states.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.r_state?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        State is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1">
                    <hr className="xl:hidden mt-3" />
                    <label
                      htmlFor="lga"
                      className="mt-3 block text-gray-900 text-lg"
                    >
                      Current LGA of Residence
                    </label>
                    <select
                      name="r_lga"
                      onChangeCapture={handleChange}
                      {...register("r_lga", { required: true })}
                      aria-invalid={errors.r_lga ? "true" : "false"}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select Local Government</option>
                      {lgs.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.r_lga?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Local Government is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-1 w-1/2">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={() => {
                        setStep(1);
                      }}
                    >
                      {"<<"}Previous
                    </button>
                  </div>
                  <div className="flow-left">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={handleSubmit(onSubmitReporter)}
                    >
                      Next{">>"}
                      {isLoading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {step === 6 && (
              <div className="">
                {profileType === "individual" && (
                  <>
                    <label
                      className="text-center block text-gray-900 text-lg"
                      htmlFor="are_you_the_owner"
                    >
                      Are you the owner of the stolen vehicle?
                    </label>
                    <div onChange={(e)=>{handleChange(e)}} className="text-center">
                      <label>
                        <input
                          type="radio"
                          className="mr-2"
                          name="owner"
                          value="yes"
                          required={true}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          className="ml-8 mr-2 mb-4"
                          name="owner"
                          value="no"
                          required={true}
                        />
                        No
                      </label>
                    </div>
                  </>
                )}
                <div
                  className={` ${
                    (owner === undefined || owner === "") && "hidden"
                  }`}
                >
                  <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                    Owner Details
                  </p>
                  {profileType === "individual" && (
                    <>
                      <div className="xl:flex">
                        <div
                          className={`xl:flex-1 w-full mr-3 ${
                            inputs.owner === "yes" && "hidden"
                          }`}
                        >
                          {" "}
                          <hr className="xl:hidden mt-3" />
                          <label
                            htmlFor="relationship"
                            className="block text-gray-900 text-lg"
                          >
                            Relationship to Owner
                          </label>
                          <input
                            name="relationship"
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, relationship: e.target.value })}}
                            type="text"
                            {...register("relationship", {
                              required: inputs.owner === "no" ? true : false,
                            })}
                            aria-invalid={
                              errors.relationship ? "true" : "false"
                            }
                            className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                          />
                          {errors.relationship?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Tell us how you are related to the owner
                            </p>
                          )}
                        </div>
                        <hr className="xl:hidden mt-4" />
                        <div className="xl:flex-1 mr-3">
                          <label
                            htmlFor="id_type"
                            className="block text-gray-900 text-lg"
                          >
                            Owner ID Type
                          </label>
                            <>
                              <select
                                name="id_type"
                                {...register("id_type", {
                                  required: true,
                                })}
                                // onChange={handleChange}
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, id_type: e.target.value })}}
                                className="w-full bg-white rounded-lg h-12 text-lg leading-loose hover:border-green-800 py-1 px-2"
                              >
                                <option value="">Select an ID</option>
                                {profileType === "individual" && (
                                  <option value="nin">
                                    National Identification Number(NIN)
                                  </option>
                                )}
                              </select>
                            </>
                        </div>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="owner_id_number"
                            className="block text-gray-900 text-lg"
                          >
                            Owner ID Number
                          </label>
                            <>
                              <input
                                type="text"
                                name="owner_id_number"
                                id="owner_id_number"
                                // onChange={handleChange}
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, id_number: e.target.value })}}
                                className="w-full h-12 uppercase text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                              />
                            </>
                        </div>
                        {/* <div
                          className={`xl:flex-1 mr-3`}
                        >
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="gender"
                            className="block text-gray-900 text-lg"
                          >
                            Gender
                          </label>
                            <>
                              <select
                                name="gender"
                                // onChange={handleChange}
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, gender: e.target.value })}}
                                {...register("gender", { required: false })}
                                className="w-full rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                              >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </>
                         </div> */}
                      </div>
                      <div className={`xl:flex mt-3`}>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="firstname"
                            className="block text-gray-900 text-lg"
                          >
                            {profileType === "individual"
                              ? "First Name"
                              : "Organization name"}
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            // readOnly={owner === "yes" ? true : false}
                            // value={ownerDetails.first_name || ""}
                            {...register("first_name", {
                              required: owner === "yes" ? false : false,
                            })}
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, first_name: e.target.value })}}
                            className="w-full h-12 text-lg rounded-lg border-2 hover:border-green-800 py-1 px-2"
                          />
                        </div>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="middle_name"
                            className="block text-gray-900 text-lg"
                          >
                            Middle Name
                          </label>
                          {/* )} */}
                          {/* {inputs.o_profile_type === "individual" && ( */}
                          <input
                            type="text"
                            name="middle_name"
                            // readOnly={owner === "yes" ? true : false}
                            // value={ownerDetails.middle_name || ""}
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, middle_name: e.target.value })}}
                            {...register("middle_name", { required: false })}
                            className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                          />
                        </div>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="lastname"
                            className="block text-gray-900 text-lg"
                          >
                            Last Name
                          </label>
                          {/* )} */}
                          {/* {inputs.o_profile_type === "individual" && ( */}
                          <input
                            type="text"
                            // value={ownerDetails.last_name || ""}
                            name="last_name"
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, last_name: e.target.value })}}
                            // readOnly={owner === "yes" ? true : false}
                            {...register("last_name", { required: false })}
                            className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                          />
                        </div>
                      </div>
                      <div className="xl:flex mt-3">
                        <div
                          className={`xl:flex-1 mr-3 ${
                            owner === "yes" && "hidden"
                          }`}
                        >
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="gender"
                            className="block text-gray-900 text-lg"
                          >
                            Gender
                          </label>
                          <select
                            name="gender"
                            // value={ownerDetails.gender || ""}
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, gender: e.target.value })}}
                            {...register("gender", { required: false })}
                            // readOnly={owner === "yes" ? true : false}
                            className="w-full rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="phone"
                            className="block text-gray-900 text-lg"
                          >
                            Phone
                          </label>
                            <>
                              <input
                                type="text"
                                // readOnly={owner === "yes" ? false : false}
                                // value={ownerDetails.phone || ""}
                                name="phone"
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, phone: e.target.value })}}
                                // onChange={handleChange}
                                // {...register("phone", { required: false })}
                                className="w-full rounded-lg border-2 hover:border-green-800 h-12 text-lg py-1 px-2"
                              />
                            </>
                        </div>
                        <div className="xl:flex-1 xl:mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="email"
                            className="block text-gray-900 text-lg"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            // readOnly={ownerDetails.email === "" ? false : true}
                            {...register("email", {
                              required: owner === "yes" ? false : true,
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                            // value={ownerDetails.email || ""}
                            // onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, email: e.target.value })}}
                            className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                          />
                          {errors.email?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Email is required
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="xl:flex">
                        <div className="xl:flex-1 xl:mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="state"
                            className="block text-gray-900 text-lg"
                          >
                            Current State of Residence
                          </label>
                          <>
                              <select
                                name="state"
                                {...register("state", { required: true })}
                                aria-invalid={errors.state ? "true" : "false"}
                                // onChange={handleChange}
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, state: e.target.value })}}
                                className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                              >
                                <option value="">Select State</option>
                                {states.map((val, index) => {
                                  return (
                                    <option key={index} value={val}>
                                      {val}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.state?.type === "required" && (
                                <p
                                  role="alert"
                                  className="text-sm text-red-500"
                                >
                                  State is required
                                </p>
                              )}
                            </>
                        </div>
                        <div className="xl:flex-1 mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="lg"
                            className="block text-gray-900 text-lg"
                          >
                            Current LGA of Residence
                          </label>
                          <>
                              <select
                                name="lga"
                                // onChange={handleChange}
                                onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, lga: e.target.value })}}
                                {...register("lga", { required: true })}
                                aria-invalid={errors.lga ? "true" : "false"}
                                className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                              >
                                <option value="">
                                  Select Local Government
                                </option>
                                {lgs.map((val, index) => {
                                  return (
                                    <option key={index} value={val}>
                                      {val}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.lga?.type === "required" && (
                                <p
                                  role="alert"
                                  className="text-sm text-red-500"
                                >
                                  Local Government is required
                                </p>
                              )}
                            </>
                        </div>
                        <div className="xl:flex-1 xl:mr-3">
                          <hr className="xl:hidden mt-4" />
                          <label
                            htmlFor="present_address"
                            className="block text-gray-900 text-lg"
                          >
                            Current Residential Address
                          </label>
                          <input
                            type="text"
                            name="present_address"
                            {...register("present_address", {
                              required: false,
                            })}
                            aria-invalid={
                              errors.present_address ? "true" : "false"
                            }
                            onChange={handleChange}
                            onChangeCapture={(e)=>{setOwnerDetails({ ...ownerDetails, present_adddress: e.target.value })}}
                            className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                          />
                          {errors.present_address?.type === "required" && (
                            <p role="alert" className="text-sm text-red-500">
                              Address is required
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {profileType === "corporate" && <></>}
                  <div className="flex">
                    <div className="flex-1 w-1/2">
                      <button
                        className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                        onClick={() => {
                          setStep(1);
                        }}
                        disabled={false}
                      >
                        {"<<"}Previous
                      </button>
                    </div>
                    <div className="flow-left">
                      <button
                        className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                        onClick={handleSubmit(nextStep)}
                      >
                        Next{">>"}
                        {isLoading && <Loader />}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step === 7 ? (
              <div>
                {/* <div className="text-center mb-2 text-xl font-semibold">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Vehicle Details
                </p>
                <div className="xl:flex">
                  <div className="xl:flex-1">
                    <label
                      htmlFor="license_plate"
                      className="block text-gray-900 text-lg"
                    >
                      Licence Plate Number
                    </label>
                    <input
                      type="text"
                      {...register("license_plate", { required: true })}
                      aria-invalid={errors.license_plate ? "true" : "false"}
                      className="w-full uppercase rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    />
                    {errors.license_plate?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        License Plate Number is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3 xl:ml-3">
                    <label
                      htmlFor="chassisNumber"
                      className="block text-gray-900 text-lg"
                    >
                      VIN/Chassis Number
                    </label>
                    <input
                      type="text"
                      {...register("chassis_number", {
                        required: "Chassis Number is required",
                        pattern: {
                          value: /^[^IOQioq*,._-]*$/,
                          message: "Invalid Chassis Number",
                        },
                        minLength: {
                          value: 17,
                          message: "Max 17 characters",
                        },
                        maxLength: {
                          value: 17,
                          message: "Chassis Number should be 17 characters",
                        },
                      })}
                      className="w-full uppercase rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                      //   required
                      // pattern="^[^IOQioq*,._-]*$"
                    />
                    {errors.chassis_number ? (
                      <>
                        {errors.chassis_number?.type === "required" && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.chassis_number.message}
                          </p>
                        )}
                        {errors.chassis_number?.type === "pattern" && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.chassis_number.message}
                          </p>
                        )}
                        {(errors.chassis_number?.type === "maxLength" ||
                          errors.chassis_number?.type === "minLength") && (
                          <p role="alert" className="text-sm text-red-500">
                            {errors.chassis_number.message}
                          </p>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    {" "}
                    <label
                      htmlFor="engine_number"
                      className="block text-gray-900 text-lg"
                    >
                      Engine Number
                    </label>
                    <input
                      type="text"
                      {...register("engine_number", { required: true })}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    />
                    {errors.engine_number?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Engine Number is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="xl:flex mt-3">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="manufacturer"
                      className="block text-gray-900 text-lg"
                    >
                      Make
                    </label>
                    <select
                      {...register("manufacturer", { required: true })}
                      onChange={handleChange}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select Manufacturer</option>
                      {manufacturers.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.manufacturer?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Choose a Manufacturer
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="model"
                      className="block text-gray-900 text-lg"
                    >
                      Model
                    </label>
                    <select
                      {...register("model", { required: true })}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select Model</option>
                      {models.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.model?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Choose a Vehicle Model
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1">
                    <label
                      htmlFor="vehicle_type"
                      className="block text-gray-900 text-lg"
                    >
                      Vehicle Type
                    </label>
                    <select
                      {...register("vehicle_type", { required: true })}
                      className="h-12 text-lg w-full rounded-lg border-2 hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select Vehicle Type</option>
                      {vehicle_types.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.vehicle_type?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Vehicle Type is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="xl:flex xl:mt-3">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="year"
                      className="block text-gray-900 text-lg"
                    >
                      Year
                    </label>
                    <select
                      {...register("year", { required: true })}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Specify Year of Manufacture</option>
                      {years.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.year?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Year of Vehicle Manufacture is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 mr-3">
                    <label
                      htmlFor="vehicleUsage"
                      className="block text-gray-900 text-lg"
                    >
                      Vehicle Usage
                    </label>
                    <select
                      name="vehicle_usage"
                      {...register("vehicle_usage", { required: true })}
                      required
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select Usage</option>
                      <option value="Private">Private</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Government">Government</option>
                    </select>
                    {errors.vehicle_usage?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Vehicle Usage is required
                      </p>
                    )}
                  </div>

                  <div className="xl:flex-1">
                    {" "}
                    <label
                      htmlFor="color"
                      className="block text-gray-900 text-lg"
                    >
                      Color
                    </label>
                    <select
                      {...register("color", { required: true })}
                      onChange={handleChange}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select Color</option>
                      {colors.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.color?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Color is required
                      </p>
                    )}
                  </div>
                </div>
                <div className="xl:flex mt-3">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="source_of_vehicle"
                      className="block text-gray-900 text-lg"
                    >
                      Source of Vehicle
                    </label>
                    <select
                      name="source_of_vehicle"
                      {...register("source_of_vehicle", { required: true })}
                      required
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select Source </option>
                      <option value="Imported">Imported</option>
                      <option value="Locally Assembled">
                        Locally Assembled
                      </option>
                    </select>
                    {errors.source_of_vehicle?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Vehicle Souce is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="mla"
                      className="block text-gray-900 text-lg"
                    >
                      Motor Vehicle Licensing Authority (MVLA)
                    </label>
                    <select
                      {...register("mla", { required: true })}
                      onChange={handleChange}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select State</option>
                      {states.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.mla?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        MLA is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="mla_address"
                      className="block text-gray-900 text-lg"
                    >
                      MVLA Address
                    </label>
                    <input
                      type="text"
                      value={inputs.mla + " State, Nigeria"}
                      readOnly={true}
                      {...register("mla_address", { required: true })}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    />
                    {errors.mla_address?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        MLA Address is required
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 w-1/2">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={() => {
                        if (profileType === "corporate") {
                          setStep(10);
                        } else {
                          prevStep();
                        }
                      }}
                    >
                      {"<<"}Previous
                    </button>
                  </div>
                  <div className="flow-left">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={handleSubmit(nextStep)}
                    >
                      Next{">>"}
                      {isLoading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {step === 8 ? (
              <div>
                {/* <div className="text-center text-xl font-semibold">
                  STOLEN VEHICLE REPORT PORTAL
                </div> */}
                <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Report Details - Reported By{" "}
                  {owner === "yes" ? "Owner" : inputs.relationship_to_owner}
                </p>

                <div className="xl:flex mt-3">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="stolen_time"
                      className="block text-gray-900 text-lg"
                    >
                      Date and Time Stolen
                    </label>
                    <DateTimePicker
                      name="stolen_time"
                      onChange={setTimeOfStealing}
                      value={stolen_time}
                      // {...register("timeOfStolen")}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    />
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="state_stolen"
                      className="block text-gray-900 text-lg"
                    >
                      State Where Stolen
                    </label>
                    <select
                      name="state_stolen"
                      {...register("state_stolen", { required: true })}
                      onChange={handleStolenStateChange}
                      // onClick={() => {
                      //   getFormations();
                      // }}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select State</option>
                      {states.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.state_stolen?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        State (Where the vehicle was stolen) is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1">
                    <label
                      htmlFor="stolenLg"
                      className="block text-gray-900 text-lg"
                    >
                      Local Government where Stolen
                    </label>
                    <select
                      name="lg_stolen"
                      {...register("lg_stolen", { required: true })}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    >
                      <option value="">Select LG</option>
                      {lgs.map((val, index) => {
                        return (
                          <option key={index} value={val}>
                            {val}
                          </option>
                        );
                      })}
                    </select>
                    {errors.lg_stolen?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Local Government is required
                      </p>
                    )}
                  </div>
                </div>

                <div className="xl:flex xl:mt-4">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="stolen_location"
                      className="block text-gray-900 text-lg"
                    >
                      Place/Location where Stolen
                    </label>
                    <input
                      type="text"
                      {...register("stolen_location", { required: true })}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      aria-invalid={errors.stolen_location ? "true" : "false"}
                    />
                    {errors.stolen_location?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Address of stolen vehicle is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="police_formation"
                      className="block text-gray-900 text-lg"
                    >
                      Police Formation Where Reported
                    </label>
                    <select
                      name="police_formation"
                      {...register("police_formation", { required: true })}
                      aria-invalid={errors.police_formation ? "true" : "false"}
                      onChange={handleChange}
                      className="w-full rounded-lg border-2 hover:border-green-800 py-1 px-2 h-12 text-lg"
                    >
                      <option value="">Select Police Formation</option>
                      {police_formations.map((arr, index) => {
                        return (
                          <option key={index} value={arr.name}>
                            {arr.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.police_formation?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Police station of report is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1">
                    <label
                      htmlFor="date_reported"
                      className="block text-gray-900 text-lg"
                    >
                      Date Reported
                    </label>
                    <DateTimePicker
                      onChange={setDateReported}
                      value={date_reported || ""}
                      //   {...register("timeOfStolen")}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                    />
                  </div>
                </div>

                <div className="xl:flex xl:mt-3">
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="police_extract_number"
                      className="block text-gray-900 text-lg"
                    >
                      Police Extract Reference Number
                    </label>
                    <input
                      type="text"
                      {...register("extract_number", { required: true })}
                      className="w-full rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      aria-invalid={errors.extract_number ? "true" : "false"}
                    />
                    {errors.extract_number?.type === "required" && (
                      <p role="alert" className="text-sm text-red-500">
                        Police extract number is required
                      </p>
                    )}
                  </div>
                  <div className="xl:flex-1 xl:mr-3">
                    <label
                      htmlFor="mode_of_stealing"
                      className="block text-gray-900 text-lg"
                    >
                      Mode of Stealing
                    </label>

                    <select
                      name="mode_of_stealing"
                      className="w-full rounded-lg h-12 text-lg border-2 hover:border-green-800 py-1 px-2"
                      {...register("mode_of_stealing", { required: true })}
                    >
                      <option value="">Select Mode of Stealing</option>
                      <option value="Opportunistic Car Theft">
                        Opportunistic Car Theft (Removal from parked location)
                      </option>
                      <option value="Carjacking">Carjacking (Snatching)</option>
                    </select>
                  </div>

                  <div className="xl:flex-1">
                    <label
                      htmlFor="detailsDescription"
                      className="block text-gray-900 text-lg"
                    >
                      Brief Details of Theft
                    </label>
                    <textarea
                      maxLength="1000"
                      className="w-full uppercase rounded-lg border-2 h-12 text-lg hover:border-green-800 py-1 px-2"
                      {...register("detailed_description", { required: false })}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>

                <div className="flex">
                  <div className="flex-1 w-1/2">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={prevStep}
                    >
                      {"<<"}Previous
                    </button>
                  </div>
                  <div className="flow-left">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Next{">>"}
                      {isLoading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/*stpe 7 from here*/}
            {step === 9 ? (
              <div>
                <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
                  Upload Documents
                </p>
                <label
                  htmlFor="police_extract"
                  className="mt-3 block text-gray-900 text-lg"
                >
                  Police Extract (If available)
                </label>

                <input
                  type="file"
                  id="police_extract"
                  accept="image/*"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  {...register("police_extract", { required: false })}
                  aria-invalid={errors.police_extract ? "true" : "false"}
                  onChange={handleChange}
                />
                {errors.police_extract?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Police extract is required
                  </p>
                )}

                <label
                  htmlFor="police_extract"
                  className="mt-3 block text-gray-900 text-lg"
                >
                  Proof of Ownership (If available)
                </label>

                <input
                  type="file"
                  id="proof_of_ownership"
                  accept="image/*"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  {...register("proof_of_ownership", { required: false })}
                  aria-invalid={errors.proof_of_ownership ? "true" : "false"}
                  onChange={handleChange}
                />
                {errors.proof_of_ownership?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Proof of Ownership is required
                  </p>
                )}

                <label
                  htmlFor="affidavit"
                  className="mt-3 block text-gray-900 text-lg"
                >
                  Affidavit attested to over theft (if available)
                </label>

                <input
                  type="file"
                  id="affidavit"
                  accept="image/*"
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-lg-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                  {...register("affidavit", { required: false })}
                  aria-invalid={errors.affidavit ? "true" : "false"}
                  onChange={handleChange}
                />
                {errors.affidavit?.type === "required" && (
                  <p role="alert" className="text-sm text-red-500">
                    Affidavit is required
                  </p>
                )}
                <div className="flex">
                  <div className="flex-1 w-1/2">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={prevStep}
                    >
                      {"<<"}Previous
                    </button>
                  </div>
                  <div className="flow-left">
                    <button
                      className={`bg-blue-500 rounded-lg mb-6 h-12 text-center w-32 cursor-pointer mt-3 text-gray-100 px-3 py-1`}
                      onClick={handleSubmit(onUpload)}
                    >
                      Submit{""}
                      {isLoading && <Loader />}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </form>
        </div>
        <div
          className={`xl:flex-initial ${step > 4 ? "xl:w-1/6" : "xl:w-2/6"}`}
        ></div>
      </div>
    </div>
  );
}
