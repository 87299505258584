import React from "react";

export default function Footer() {
  return (
    // <div className="sticky top-0 z-50">
    <footer className="py-4 inset-x-0 bottom-0 mt-9 bg-blue-400 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
      <span className="text-sm text-gray-900 sm:text-center dark:text-gray-900">
        © 2022{" "}
        <a href="#" className="hover:underline">
          Nigeria Police Force {/*™*/}
        </a>
        . All Rights Reserved.
      </span>
      <ul className="flex flex-wrap items-center mt-3 text-sm text-gray-900 dark:text-gray-900 sm:mt-0">
        <li>
          <a href="/about" className="mr-4 hover:underline md:mr-6 ">
            About
          </a>
        </li>
        <li>
          <a href="#" className="mr-4 hover:underline md:mr-6">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="#" className="mr-4 hover:underline md:mr-6">
            Licensing
          </a>
        </li>
        <li>
          <a href="#" className="hover:underline">
            Contact
          </a>
        </li>
      </ul>
    </footer>
    // </div>
  );
}
