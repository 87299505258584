const colors = [
  "Ash",
  "Aquamarine",
  "Azure",
  "Black",
  "Blue",
  "Brown",
  "Charcoal",
  "Coral",
  "Crimson",
  "Cyan",
  "Fuchsia",
  "Gold",
  "Gray",
  "Green",
  "Hot pink",
  "Ivory",
  "Khaki",
  "Lime",
  "Magenta",
  "Maroon",
  "Navy blue",
  "Olden",
  "Olive",
  "Orange",
  "Pea green",
  "Plum",
  "Purple",
  "Red",
  "Silver",
  "Teal",
  "Wheat",
  "White",
  "Wine",
  "Yellow",
];

export default colors;
