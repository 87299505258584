import React from "react";

export default function Reporter() {
  return (
    <div className="px-2 lg:px-64">
      <div className="">
        <p className="p-4 rounded-lg text-lg mt-3 bg-gray-700 font-sans md:text-lg text-gray-100 font-semi-bold mb-3">
          Reporter Details
        </p>
      </div>
      <div className="lg:flex">
        <div className="lg:flex-1">
          <label>Profile Type</label>
          <select className="w-full h-12 rounded-lg p-2 text-md bg-gray-100">
          <option value="">Select Profile Type</option>
            <option value="indivudual">Individual</option>
            <option value="corporation">Organization</option>
          </select>
        </div>
        <div className="flex-1">
        <label>ID Type</label>
          <select className="w-full h-12 rounded-lg p-2 text-md bg-gray-100">
          <option value="">Select ID Type</option>
            <option value="">Individual</option>
            <option value="corporation">Organization</option>
          </select>
        </div>
        <div className="flex-1">
          3
        </div>
      </div> 
    </div>    
  );
}
