import React, { useState } from "react";
// import { Link } from "react-router-dom";

export default function NavBar() {
  const [openMobileNav, setOpenMbileNav] = useState(false);

  const openMenu = () => {
    setOpenMbileNav(!openMobileNav);
  };

  return (
    <div className="sticky top-0 z-50">
      <nav className="flex items-center justify-between flex-wrap bg-gray-600 pb-2 px-6">
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <a href="/">
            <img src="/logo.png" alt="Police Logo" className="h-16 w-14" />
          </a>
          <span className="font-semibold text-4xl mt-1 ml-6 text-gray-100 hidden lg:block tracking-tight">
            <a href="/">
              Nigeria Police Force <br />
              <span className="text-3xl"> Central Motor Registry (CMR)</span>
            </a>
          </span>
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            onClick={openMenu}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={`w-full flex-grow lg:flex lg:items-right lg:w-auto ${
            openMobileNav ? "block" : "hidden"
          }`}
        >
          <div className="text-lg text-right lg:flex-grow mt-16">
            <a
              href="/"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
            >
              Home
            </a>
            <a
              href="/about"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
            >
              About this Service
            </a>
            <a
              href="/contact"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4"
            >
              Contact Us
            </a>
            <a
              href="#responsive-header"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white"
            ></a>
          </div>
          {/* <div>
            <a
              href="#"
              className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
            >
              Download Android Mobile
            </a>
            <a
              href="#"
              className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white mt-4 lg:mt-0"
            >
              Download IOS Mobile
            </a>
          </div> */}
        </div>
      </nav>
    </div>
  );
}
