import React from "react";
import Header from "./Header";
import Reporter from "./Reporter";

export default function ReportStolenVehicle() {
  
  return (
    <>
      <Header />
      <Reporter />
    </>    
  );
}
